import { FlexColumn, FlexRow, Icon, TypographyV2 } from "@papercup-ai/crane";
import * as React from "react";
import ImpactSvg from "../assets/svg/impact.svg";
import EthicSvg from "../assets/svg/ethics.svg";
import AmbitiousSvg from "../assets/svg/ambitious.svg";
import InterSvg from "../assets/svg/most-interdisciplinary.svg";
import EntertainingSvg from "../assets/svg/entertaining.svg";
import PeopleChoiceSvg from "../assets/svg/people-choice.svg";
import GrandSvg from "../assets/svg/grand.svg";
import PapercupTextLogo from "../assets/svg/logoText.svg";
import PapercupLogo from "../assets/svg/logoFull.svg";
import MentorSvg from "../assets/svg/mentor.svg";
import { motion } from "framer-motion";
import Confetti from "react-dom-confetti";
import ScratchCard from "react-scratchcard";

const mappingSvg = {
  "Most impactful": ImpactSvg,
  "Most ethical value": EthicSvg,
  "Most ambitious": AmbitiousSvg,
  "Most interdisciplinary": InterSvg,
  "Entertaining": EntertainingSvg,
  "People's choice": PeopleChoiceSvg,
  "Grand Prize": GrandSvg,
  "Best mentor": MentorSvg,
};

const HackathonLogo = () => {
  return (
    <FlexColumn
      background="bg-splash"
      dimensions={{ pad: "pa3" }}
      layout={{ pos: "relative" }}
      flex={{ fai: "items-center", fjc: "justify-center" }}
    >
      <FlexColumn
        dimensions={{ w: "w-100" }}
        layout={{ pos: "relative" }}
        flex={{ fai: "items-center", fjc: "justify-center" }}
        classNameOverride="hologram"
      >
        <motion.div className="item flex title flex-column  justify-center items-center" variants={item}>
          <PapercupTextLogo width={"175px"} height="35px" />
          <FlexColumn
            dimensions={{ w: "w-100", pad: ["ph2", "pb2"] }}
            background="bg-splash"
          >
            <TypographyV2.H3 colour="c-ternary" i>Supreme</TypographyV2.H3>
          </FlexColumn>
          <TypographyV2.H4 colour="c-brand">HACKATHON 2021</TypographyV2.H4>
        </motion.div>
      </FlexColumn>
    </FlexColumn>
  );
};

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
};



export const AwardsWelcome = () => {
  const [confetti, setConfetti] = React.useState(false);
  React.useEffect(() => {
    setTimeout(() => {
      setConfetti(true);
    }, 2000);
  }, []);
  return (
    <FlexColumn
      background="bg-brand"
      dimensions={{ w: "vw-100", h: "vh-100", pad: "pa5" }}
    >
      <FlexColumn
        background="bg-primary"
        dimensions={{ w: "w-100", h: "h-100", pad: "pa5" }}
        flex={{ fai: "items-center", fjc: "justify-center" }}
      >
        <motion.div
          className="container"
          variants={container}
          initial="hidden"
          animate="visible"
        >
          <motion.div className="item" variants={item}>
            <FlexRow
              flex={{
                fai: "items-center"
              }}
              dimensions={{
                pad: "pa4"
              }}
            >
            </FlexRow>
          </motion.div>

          <FlexColumn
            background="bg-splash"
            dimensions={{ pad: "pa3" }}
            layout={{ pos: "relative" }}
            flex={{ fai: "items-center", fjc: "justify-center" }}
          >
            <FlexColumn
              dimensions={{ w: "w-100" }}
              layout={{ pos: "relative" }}
              flex={{ fai: "items-center", fjc: "justify-center" }}
              classNameOverride="hologram"
            >
              <motion.div className="item flex title flex-column  justify-center items-center" variants={item}>
                <PapercupTextLogo width={"345px"} height="80px" />
                <FlexColumn
                  dimensions={{ w: "w-100", pad: ["ph2", "pb2"] }}
                  background="bg-splash"
                >
                  <TypographyV2.H2 colour="c-ternary" i>Supreme</TypographyV2.H2>
                </FlexColumn>
                <TypographyV2.H3 colour="c-brand">HACKATHON 2021</TypographyV2.H3>
              </motion.div>
            </FlexColumn>
          </FlexColumn>
          <TypographyV2.H1 colour="c-brand" style={{
            fontSize: "88px"
          }}>AWARDS</TypographyV2.H1>
        </motion.div>
        <Confetti
          active={confetti}
          config={{
            angle: 90,
            spread: 360,
            startVelocity: 40,
            elementCount: 200
          }}
        />
      </FlexColumn>

      <style jsx global>
        {`

        .hologram {
          text-align: center;
          background-image: url(https://img.freepik.com/free-photo/_40163-61.jpg?size=626&ext=jpg);  
          animation-duration: 10s;
          animation-iteration-count: infinite;
          animation-direction: alternate;
          animation-timing-function: linear;
          animation-name: fluid;
          background-size: 500%;
        }
        .hologramtext {
          text-align: center;
          background-image: url(https://img.freepik.com/free-photo/_40163-61.jpg?size=626&ext=jpg);  
          animation-duration: 10s;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          animation-iteration-count: infinite;
          animation-direction: alternate;
          animation-timing-function: linear;
          animation-name: fluid;
          background-size: 500%;
        }
        @keyframes fluid {
          from {
            background-position: top;
          }

          to {
            background-position: bottom;
          }
        }
        `}
      </style>
    </FlexColumn>
  );
};

const longArray = Array.from(Array(30).keys());
export const Filler = ({ text }: any) => {
  return (
    <FlexColumn
      background="bg-brand"
      dimensions={{ w: "vw-100", h: "vh-100", pad: "pa5" }}
      flex={{ fai: "items-center", fjc: "justify-center" }}
      style={{
        backgroundAttachment: "fixed",
        overflow: "hidden"
      }}
    >
      <motion.div
        className="container"
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {text && (
          <>
            {longArray.map(i => (
              <motion.div className="item flex" key={i} variants={item}>
                {" "}
                <FlexRow
                  background="bg-brand"
                  flex={{ fai: "items-center", fjc: "justify-center" }}
                  dimensions={{ pad: "pa4" }}
                  classNameOverride=""
                >
                  <TypographyV2.H2 classNameOverride="hologramtext">{text}</TypographyV2.H2>
                </FlexRow>
                <FlexRow
                  background="bg-brand"
                  flex={{ fai: "items-center", fjc: "justify-center" }}
                  dimensions={{ pad: "pa4" }}
                  classNameOverride=""
                >
                  <TypographyV2.H2 classNameOverride="hologramtext">{text}</TypographyV2.H2>
                </FlexRow>
                <FlexRow
                  background="bg-brand"
                  flex={{ fai: "items-center", fjc: "justify-center" }}
                  dimensions={{ pad: "pa4" }}
                  classNameOverride=""
                >
                  <TypographyV2.H2 classNameOverride="hologramtext">{text}</TypographyV2.H2>
                </FlexRow>

              </motion.div>
            ))}
          </>
        )}
      </motion.div>
    </FlexColumn>
  );
};

const Signatures = () => {
  return (
    <FlexColumn dimensions={{ w: "w-100" }} layout={
      {
        pos: "absolute",
        coor: "bottom-0"

      }
    }>
      <TypographyV2.H5 colour="c-secondary" m="mt4" classNameOverride="center">
        awared by
      </TypographyV2.H5>
      <FlexRow dimensions={{ w: "w-100", pad: "pb5" }} flex={{ fai: "items-center", fjc: "justify-center" }}>
        <FlexColumn
          dimensions={{ m: ["mt5", "mh5"] }}
          flex={{ fai: "items-center" }}
          layout={{ pos: "relative" }}
        >
          <TypographyV2.H5
            m={undefined}
            style={{
              fontFamily: "Doniyor",
              fontSize: "26px",
              position: "relative",
              right: "25px",
              bottom: "-3px",
              transform: "rotate(-3deg)"
            }}
          >
            D
          </TypographyV2.H5>
          <TypographyV2.H5
            m={undefined}
            style={{
              fontFamily: "Doniyor",
              fontSize: "26px",
              position: "absolute",
              top: "0px",
              transform: "rotate(-3deg)"
            }}
          >
            oniyor
          </TypographyV2.H5>
          <FlexRow
            border={{ b: "bt", bc: "b--gray" }}
            dimensions={{ pad: ["ph5", "pv3"] }}
          >
            <TypographyV2.H5 m={undefined}>Doniyor Ulmasov</TypographyV2.H5>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          dimensions={{ m: ["mt5", "mh5"] }}
          flex={{ fai: "items-center" }}
          layout={{ pos: "relative" }}
        >
          <TypographyV2.H5
            m={undefined}
            style={{
              fontFamily: "Margarita",
              fontSize: "18px",
              position: "relative",
              bottom: "-3px",
              transform: "rotate(-3deg)"
            }}
          >
            Alexandra Torresquintero
          </TypographyV2.H5>

          <FlexRow
            border={{ b: "bt", bc: "b--gray" }}
            dimensions={{ pad: ["ph5", "pv3"] }}
          >
            <TypographyV2.H5 m={undefined}>Alexandra Torresquintero</TypographyV2.H5>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          dimensions={{ m: ["mt5", "mh5"] }}
          flex={{ fai: "items-center" }}
          layout={{ pos: "relative" }}
        >
          <TypographyV2.H5
            style={{
              fontFamily: "Margarita",
              fontSize: "18px",
              position: "relative",
              bottom: "1px",
              transform: "rotate(-5deg)"
            }}
          >
            Amirrrrrrrrrrrrr
          </TypographyV2.H5>

          <FlexRow
            border={{ b: "bt", bc: "b--gray" }}
            dimensions={{ pad: ["ph5", "pv3"] }}
          >
            <TypographyV2.H5 m={undefined}>Amir Jirbandey</TypographyV2.H5>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          dimensions={{ m: ["mt5", "mh5"] }}
          flex={{ fai: "items-center" }}
          layout={{ pos: "relative" }}
        >
          <TypographyV2.H5
            m={undefined}
            style={{
              fontFamily: "Marlene",
              fontSize: "18px",
              position: "relative",
              bottom: "-2px",
              transform: "rotate(-3deg)"
            }}
          >
            Senior ML Engineer
          </TypographyV2.H5>

          <FlexRow
            border={{ b: "bt", bc: "b--gray" }}
            dimensions={{ pad: ["ph5", "pv3"] }}
          >
            <TypographyV2.H5 m={undefined}>Tian Huey Teh</TypographyV2.H5>
          </FlexRow>
        </FlexColumn>
        <FlexColumn
          dimensions={{ m: ["mt5", "mh5"] }}
          flex={{ fai: "items-center" }}
          layout={{ pos: "relative" }}
        >
          <TypographyV2.H5
            m={undefined}
            style={{
              fontFamily: "Vivian",
              fontSize: "18px",
              position: "relative",
              bottom: "-2px",
              transform: "rotate(-3deg)"
            }}
          >
            VivianHu
          </TypographyV2.H5>

          <FlexRow
            border={{ b: "bt", bc: "b--gray" }}
            dimensions={{ pad: ["ph5", "pv3"] }}
          >
            <TypographyV2.H5 m={undefined}>Vivian Hu</TypographyV2.H5>
          </FlexRow>
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  );
};

export const Award = ({ category, winner }: any) => {
  const [confetti, setConfetti] = React.useState(false);
  const Svg = mappingSvg[category as keyof typeof mappingSvg];

  const settings = {
    width: 500,
    height: 120,
    image: "/static/Mohaka.png",
    finishPercent: 50,
    onComplete: () => setConfetti(true)
  };
  return (
    <FlexColumn
      dimensions={{ w: "vw-100", h: "vh-100", pad: "pa4" }}
      style={{
        background: "url(/static/round.png)",
      }}
    >
      <FlexColumn
        dimensions={{ w: "w-100", h: "h-100", pad: "pa2" }}
        border={{
          b: "ba",
          bc: "b--brand"
        }}
        style={{
          background: "url(/static/skyscraper.png)",
        }}
      >
        <FlexColumn
          dimensions={{ w: "w-100", h: "h-100", pad: "pa3" }}
          border={{
            b: "ba",
            bc: "b--ternary"
          }}
          style={{
            background: "url(/static/qbkls.png)",
          }}
        >
          <FlexColumn
            dimensions={{ w: "w-100", h: "h-100", pad: "pa5" }}
            style={{
              background: "url(/static/groovepaper.png)",
            }}
            layout={{ pos: "relative" }}

          >
            <FlexRow flex={{ fjc: "justify-between", fai: "items-start" }}>
              <PapercupLogo />
              <HackathonLogo />
            </FlexRow>
            <FlexColumn flex={{ fai: "items-center" }}>
              <Svg width="200px" height="210px" />
              <TypographyV2.H3 colour="c-brand">{category}</TypographyV2.H3>
              <TypographyV2.H4 colour="c-brand" m="mt3">
                Papercup Hackathon 2021 Winner
              </TypographyV2.H4>
              <TypographyV2.H4 colour="c-brand" m="mt3">
                29/11/2021 - 03/12/2021
              </TypographyV2.H4>
              <TypographyV2.H4 colour="c-secondary" m="mt3">
                London - France - London - London - London - London - London - London - London - Brighton - London - London - London - London - London - London
              </TypographyV2.H4>
              <TypographyV2.H5 colour="c-secondary" m="mt4">
                has been awarded to
              </TypographyV2.H5>
              <Confetti
                active={confetti}
                config={{
                  angle: 90,
                  spread: 760,
                  startVelocity: 40,
                  elementCount: 200
                }}
              />
              <ScratchCard {...settings}>
                <FlexColumn
                  background="bg-secondary"
                  dimensions={{ pad: "pa3", m: "mt4" }}
                  border={{ b: "ba", bc: "b--gray-20", bw: "bw2" }}
                  flex={{ fjc: "justify-between", fai: "items-center" }}
                >
                  <TypographyV2.H3 colour="c-brand" m={undefined}>
                    {winner}
                  </TypographyV2.H3>
                </FlexColumn>
              </ScratchCard>
            </FlexColumn>

            <Signatures />
          </FlexColumn>
        </FlexColumn>
      </FlexColumn>

    </FlexColumn>
  );
};

export const ScrollBy100Vh = () => {
  return (
    <div
      style={{
        position: "fixed",
        zIndex: 100,
        bottom: 50,
        right: 50,
        width: "50px",
        height: "50px",
        borderRadius: "100%",
        color: "white",
        fontSize: "38px",
        cursor: "pointer"
      }}
      className="bg-splash flex items-center c-primary justify-center"
      onClick={() => {
        window.scrollBy({
          top: window.innerHeight,
          behavior: "smooth"
        });
      }}
    >
      &#9759;
    </div>
  );
};
