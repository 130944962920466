import { FlexColumn } from "@papercup-ai/crane";
import * as React from "react";
import {
  Award,
  AwardsWelcome,
  Filler,
  ScrollBy100Vh
} from "../src/Awards/Awards";

const WINNERS: any = {
  "Most impactful": "Project Electio 2.5",
  "Most ethical value": "The WaveRuNNers 3.0",
  "Most ambitious": "Athena 1.5",
  "Most interdisciplinary": "The WaveRuNNers 2.0",
  "Entertaining": "Jesse's Angels",
  "People's choice": "Gamificazards",
  "Grand Prize": "Gamificazards",
  "Best mentor": "Doniyor"
}

export default () => (
  <FlexColumn
  background={"bg-primary"}
  flex={{
    fai: "items-center",
    fjc: "justify-center"
  }}
  style={{ overflow: "hidden" }}
>
    <AwardsWelcome />
    {Object.keys(WINNERS).map(key => (
      <>
        <Filler text={key.toUpperCase()} />
        <Award key={key} winner={WINNERS[key]} category={key} />
      </>
    ))}
    <ScrollBy100Vh />
    </FlexColumn>
);

